




























import { Component, PropSync, Vue } from 'vue-property-decorator';
import DeleteCustomInvestorGoalModalViewModel
  from '@/vue-app/view-models/components/goals-dashboard/delete-goals/delete-custom-investor-goal-modal-view-model';

@Component({
  name: 'DeleteCustomInvestorGoalModal',
  components: {
    DeleteCustomInvestorGoalDistributeBalance: () => import('@/vue-app/components/goals-dashboard/delete-goals/DeleteCustomInvestorGoalDistributeBalance.vue'),
    DeleteCustomInvestorGoalConfirm: () => import('@/vue-app/components/goals-dashboard/delete-goals/DeleteCustomInvestorGoalConfirm.vue'),
    DeleteCustomInvestorGoalSuccess: () => import('@/vue-app/components/goals-dashboard/delete-goals/DeleteCustomInvestorGoalSuccess.vue'),
  },
})
export default class DeleteCustomInvestorGoalModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('customInvestorGoalId', { type: String, required: true })
  custom_investor_goal_id!: string;

  delete_custom_investor_goal_model = Vue.observable(new DeleteCustomInvestorGoalModalViewModel());

  closeModal() {
    this.delete_custom_investor_goal_model.refreshTheCurrentPageIfTheCurrentStepIsTheLastStep();
    this.synced_is_open = false;
  }

  showRecurringDepositDialog() {
    this.$emit('showRecurringDepositDialog');
  }

  prevStep() {
    if (this.delete_custom_investor_goal_model.current_step > 0) {
      this.delete_custom_investor_goal_model.prevStep();
    } else {
      this.synced_is_open = false;
    }
  }

  created() {
    this.delete_custom_investor_goal_model.getCustomInvestorGoal(this.custom_investor_goal_id);
  }
}
