import TYPES from '@/types';

// Application
import GetCustomInvestorGoalSearchByCustomerQuery
  from '@/modules/flagship/custom-investor-goal/application/query/get-custom-investor-goal-search-by-customer-query';
import DeleteCustomInvestorGoalStateManagerService
  from '@/modules/flagship/custom-investor-goal/application/service/delete-custom-investor-goal-state-manager-service';

// Domain
import DeleteCustomInvestorGoalState
  from '@/modules/flagship/custom-investor-goal/domain/state/delete-custom-investor-goal-state';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class DeleteCustomInvestorGoalModalViewModel {
  @Inject(TYPES.GET_CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetCustomInvestorGoalSearchByCustomerQuery;

  @Inject(TYPES.DELETE_CUSTOM_INVESTOR_GOAL_STATE_MANAGER_SERVICE)
  private readonly state_manager_service!: DeleteCustomInvestorGoalStateManagerService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.delete-custom-investor-goal';

  private STEPS = [
    'DeleteCustomInvestorGoalDistributeBalance',
    'DeleteCustomInvestorGoalConfirm',
    'DeleteCustomInvestorGoalSuccess',
  ];

  current_step = 0;

  current_component = this.STEPS[this.current_step];

  state: DeleteCustomInvestorGoalState;

  constructor(state?: DeleteCustomInvestorGoalState) {
    this.state_manager_service.restore();
    this.state = state || this.state_manager_service.get();
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  setCorrectMenuSteps = () => {
    const goal = this.state.custom_investor_goal;
    if (goal.active_goal_amount === 0) {
      this.STEPS.splice(0, 1);
    }
    this.current_component = this.STEPS[this.current_step];
  }

  nextStep = () => {
    if (this.current_step < this.STEPS.length - 1) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  changeLoadingStep = (loading: boolean) => {
    this.state.is_loading = loading;
  }

  getCustomInvestorGoal = async (custom_investor_goal_id: string) => {
    try {
      const custom_investor_goal_a = await this.get_search_by_customer_query.execute(true);

      const custom_investor_goal = custom_investor_goal_a.filter(
        (goal) => goal.id === custom_investor_goal_id,
      );

      this.state.custom_investor_goal.custom_investor_goal_id = custom_investor_goal[0].id;
      this.state.custom_investor_goal.goal_name = custom_investor_goal[0].goal_name;
      this.state.custom_investor_goal.active_goal_amount = custom_investor_goal[0]
        .investor_goal.active_goal_amount!;
      // eslint-disable-next-line max-len
      this.state.custom_investor_goal.recurrent_assigned_amount = custom_investor_goal[0]
        .investor_goal.recurrent_assigned_amount!;
      this.state.custom_investor_goal.associated_product_id = custom_investor_goal[0]
        .investor_goal.associated_product.id!;
      this.state.custom_investor_goal.associated_product_name = custom_investor_goal[0]
        .investor_goal.associated_product.name!;
      this.setCorrectMenuSteps();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_custom_investor_goal'));
    }
  }

  refreshTheCurrentPageIfTheCurrentStepIsTheLastStep = () => {
    if (this.current_step === this.STEPS.length - 1) {
      window.location.href = '/goals-dashboard';
    }
  }
}
